type ServiceGroupsBlueprint = {
  Activities: string[];
  Agents: string[];
  Assets: string[];
  Commands: string[];
  Dashboards: string[];
  Events: string[];
  Files: string[];
  Integrations: string[];
  "Integration-testing": string[];
  Metrics: string[];
  Monitors: string[];
  Orbits: string[];
  Plans: string[];
  Passes: string[];
  Plugins: string[];
  Streams: string[];
  "Teams and Roles": string[];
  Tenant: string[];
  Records: string[];
  Resources: string[];
  Procedures: string[];
  "Platform Usage": string[];
  Route: string[];
  Requirements: string[];
  Requests: string[];
  Console: string[];
  Kafka: string[];
  Infrastructure: string[];
  Ungrouped: string[];
};

export const serviceGroupsBlueprint: ServiceGroupsBlueprint = {
  Activities: ["activities-repository"],
  Agents: ["agents-repository", "leanspace-agent"],
  Assets: ["asset-repository"],
  Commands: ["commands-repository"],
  Console: ["console"],
  Dashboards: ["dashboard-repository"],
  Events: [
    "events-repository",
    "events-service-bus-lambda",
    "events-stream-lambda",
    "messaging-authorizer-repository",
  ],
  Integrations: [
    "integration-leafspace-repository",
    "valispace-integrations-repository",
    "integration-exotrail-repository",
  ],
  "Integration-testing": ["integration-testing"],
  Files: ["files-repository"],
  Metrics: [
    "lambda-metrics-ingestion-processing",
    "lambda-telemetry-metrics-ingestion-forwarder",
    "metrics-lambda-ingestion-persistence-support",
    "metrics-repository",
  ],
  Monitors: [
    "monitors-data-processing-lambda",
    "monitors-evaluation-splitter-lambda",
    "monitors-live-evaluation-lambda",
    "monitors-notifications-lambda",
    "monitors-repository",
  ],
  Orbits: [
    "orbits-ephemeris-ingestion-lambda",
    "orbits-propagations-and-events-generation-lambda",
    "orbits-repository",
  ],
  Plans: ["plans-repository"],
  Passes: ["passes-repository"],
  Plugins: ["plugins-repository"],
  Streams: [
    "lambda-packet-decoder",
    "streams-lambda-ingestion-persistence-support",
    "streams-repository",
  ],
  "Teams and Roles": ["teams-repository", "leanspace-open-policy-agent"],
  Tenant: ["tenant-repository"],
  Records: ["records-repository", "records-upload-processing-lambda"],
  Resources: ["resources-repository"],
  Procedures: ["procedures-repository"],
  "Platform Usage": [
    "data-usage-monitoring-lambda",
    "platform-usage-repository",
  ],
  Route: ["leanspace-route", "routes-repository"],
  Requirements: ["requirements-repository"],
  Requests: ["requests-repository"],
  Kafka: ["kafka-authorizer-repository", "kafka-base-image"],
  Infrastructure: [
    "api-gateway-authorizer",
    "leanspace-infra",
    "leanspace-logforwarder-image",
    "leanspace-opensearch-snapshot-lambda",
    "messaging-authorizer-repository",
    "rate-limiting-lambda",
    "feature-enablement-lambda",
  ],
  Ungrouped: [
    // all repositories that are not in the above groups will be put here
    // if you see a repo here, it means it should be added to the above groups
  ],
};

const isKeyOfServiceGroupsBlueprint = (
  key: string,
  obj: ServiceGroupsBlueprint
): key is keyof ServiceGroupsBlueprint => {
  return key in obj;
};

export const getServiceGroups = (manifest: Record<string, string>) => {
  const result: ServiceGroupsBlueprint = {
    Activities: [],
    Agents: [],
    Assets: [],
    Commands: [],
    Dashboards: [],
    Events: [],
    Files: [],
    Integrations: [],
    "Integration-testing": [],
    Metrics: [],
    Monitors: [],
    Orbits: [],
    Plans: [],
    Passes: [],
    Plugins: [],
    Streams: [],
    "Teams and Roles": [],
    Tenant: [],
    Records: [],
    Resources: [],
    Procedures: [],
    "Platform Usage": [],
    Route: [],
    Requirements: [],
    Requests: [],
    Console: [],
    Kafka: [],
    Infrastructure: [],
    Ungrouped: [],
  };

  Object.entries(manifest).forEach(([repoName]) => {
    for (const [group, repos] of Object.entries(serviceGroupsBlueprint)) {
      if (repos.includes(repoName)) {
        result[group as keyof ServiceGroupsBlueprint].push(repoName);
        return;
      }
    }
    result.Ungrouped.push(repoName);
  });
  // Sort the result to show groups in alphabetical order based on group name
  const sortedResult = Object.keys(result)
    .sort((a, b) => a.localeCompare(b))
    .reduce((acc, key) => {
      if (isKeyOfServiceGroupsBlueprint(key, result)) {
        acc[key] = result[key];
      }
      return acc;
    }, {} as ServiceGroupsBlueprint);

  return sortedResult;
};
