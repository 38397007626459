import { Octokit } from "@octokit/rest";
import { Endpoints } from "@octokit/types";
import get from "lodash/get";
import omit from "lodash/omit";
import { useMutation, useQueries, useQuery, useQueryClient } from "react-query";
import { GITHUB_WORKFLOW_RUN_STATUSES } from "Types/githubWorkflowRuns";

const octokit = new Octokit({
  auth: process.env.REACT_APP_GH_API_PAT,
});

const WORKFLOW_RUN_KEY = "workflow_runs";

type WithOptionalOwner<T> = Omit<T, "owner"> & {
  owner?: string;
};

export const useDeploymentList = (
  deploymentParams: WithOptionalOwner<
    Endpoints["GET /repos/{owner}/{repo}/deployments"]["parameters"]
  >[]
) => {
  return useQueries(
    deploymentParams.map((params) => ({
      queryKey: ["deployments_list", params],
      queryFn: () =>
        octokit.rest.repos.listDeployments({
          owner: params.owner || "leanspace",
          per_page: 1,
          ...omit(params, "owner"),
        }),
    }))
  );
};

export const useDeploymentStatuses = (
  deploymentParams: WithOptionalOwner<
    Endpoints["GET /repos/{owner}/{repo}/deployments"]["parameters"]
  >[]
) => {
  const deploymentsLists = useDeploymentList(deploymentParams);

  const deploymentIds = deploymentsLists.map((deploymentsResult) =>
    get(deploymentsResult, "data.data.[0].id", null)
  );

  return useQueries(
    deploymentIds.map((deployment_id, index) => ({
      queryKey: ["deployment_status", deployment_id],
      queryFn: () =>
        octokit.rest.repos.listDeploymentStatuses({
          deployment_id: Number(deployment_id),
          owner: deploymentParams[index].owner || "leanspace",
          repo: deploymentParams[index].repo,
          per_page: 1,
        }),
      enabled: Boolean(deployment_id),
    }))
  );
};

export const useReleases = (
  params: WithOptionalOwner<
    Endpoints["GET /repos/{owner}/{repo}/releases"]["parameters"]
  >
) => {
  return useQuery(["releases", params], () =>
    octokit.rest.repos.listReleases({
      owner: params.owner || "leanspace",
      ...omit(params, "owner"),
    })
  );
};

export const useTriggerWorkflow = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      params: WithOptionalOwner<
        Endpoints["POST /repos/{owner}/{repo}/actions/workflows/{workflow_id}/dispatches"]["parameters"]
      >
    ) => {
      return octokit.rest.actions.createWorkflowDispatch({
        owner: params.owner || "leanspace",
        ...omit(params, "owner"),
      });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [WORKFLOW_RUN_KEY] }),
  });
};

export const useFileContent = (repo: string, path: string) => {
  return useQuery(["manifest", repo, path], async () => {
    const response = await octokit.rest.repos.getContent({
      owner: "leanspace",
      repo,
      path,
    });

    const content = get(response, "data.content", null);
    return content ? atob(content) : null;
  });
};

export const useWorkflowRuns = (repo: string, workflowId: string) => {
  return useQuery({
    queryKey: [WORKFLOW_RUN_KEY, repo, workflowId],
    queryFn: () =>
      octokit.rest.actions.listWorkflowRunsForRepo({
        owner: "leanspace",
        repo: repo,
        path: `.github/workflows/${workflowId}`,
      }),
  });
};

export const usePollWorkflowRunUntilCompleted = (
  repo: string,
  workflowRunId: number | undefined
) => {
  return useQuery(
    [WORKFLOW_RUN_KEY, repo, workflowRunId],
    async () => {
      return await octokit.rest.actions.getWorkflowRun({
        owner: "leanspace",
        repo: repo,
        run_id: workflowRunId as number, // protected by 'enabled'
      });
    },
    {
      enabled: Boolean(workflowRunId),
      refetchInterval: (run) =>
        [
          GITHUB_WORKFLOW_RUN_STATUSES.success,
          GITHUB_WORKFLOW_RUN_STATUSES.failure,
          GITHUB_WORKFLOW_RUN_STATUSES.completed,
        ].indexOf(run?.data?.status as GITHUB_WORKFLOW_RUN_STATUSES) === -1
          ? 5000
          : false,
    }
  );
};
