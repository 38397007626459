import { Box, Divider, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

import SearchForMember from "./SearchForMember";

const User: React.FC = ({}) => {
  const [userName, setUserName] = useState<string | null>(null);

  return (
    <>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <>
          <Typography sx={{ padding: 2 }} variant="h5">
            Member
          </Typography>
          <TextField
            label="Type member name"
            name="membername"
            id="membername"
            onChange={(event) => setUserName(event.target.value)}
          />
          <Divider sx={{ margin: 2 }} />
          {userName && <SearchForMember name={userName} />}
        </>
      </Box>
    </>
  );
};

export default User;
