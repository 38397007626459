export enum GITHUB_WORKFLOW_RUN_STATUSES {
  completed = "completed",
  action_required = "action_required",
  cancelled = "cancelled",
  failure = "failure",
  neutral = "neutral",
  skipped = "skipped",
  stale = "stale",
  success = "success",
  timed_out = "timed_out",
  in_progress = "in_progress",
  queued = "queued",
  requested = "requested",
  waiting = "waiting",
  pending = "pending",
}

export enum GITHUB_WORKFLOW_RUN_CONCLUSIONS {
  failure = "failure",
  success = "success",
  cancelled = "cancelled",
}
