import { Box, Divider } from "@mui/material";
import React from "react";

import MpsDemoScript from "./mps";

const DemoScripts: React.FC = ({}) => {
  return (
    <>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <MpsDemoScript />
      </Box>
      <Divider sx={{ my: 1 }} />
    </>
  );
};

export default DemoScripts;
