import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { RestEndpointMethodTypes } from "@octokit/plugin-rest-endpoint-methods";
import React, { PropsWithChildren } from "react";
import {
  GITHUB_WORKFLOW_RUN_CONCLUSIONS,
  GITHUB_WORKFLOW_RUN_STATUSES,
} from "Types/githubWorkflowRuns";

interface MpsDemoScriptStepperListProps {
  run: RestEndpointMethodTypes["actions"]["getWorkflowRun"]["response"];
  lastRunFetch: number;
}

interface Step {
  label: string;
  github_statuses: GITHUB_WORKFLOW_RUN_STATUSES[];
  index: number;
}

const MpsDemoScriptStepper: React.FC<
  PropsWithChildren<MpsDemoScriptStepperListProps>
> = ({ run, lastRunFetch }) => {
  const status = run.data.status as GITHUB_WORKFLOW_RUN_STATUSES;

  const steps: Step[] = [
    {
      index: 0,
      label: "Queued",
      github_statuses: [
        GITHUB_WORKFLOW_RUN_STATUSES.queued,
        GITHUB_WORKFLOW_RUN_STATUSES.pending,
      ],
    },
    {
      index: 1,
      label: "In Progress",
      github_statuses: [GITHUB_WORKFLOW_RUN_STATUSES.in_progress],
    },
    {
      index: 2,
      label: "Completed",
      github_statuses: [
        GITHUB_WORKFLOW_RUN_STATUSES.completed ||
          GITHUB_WORKFLOW_RUN_STATUSES.failure ||
          GITHUB_WORKFLOW_RUN_STATUSES.success,
      ],
    },
  ];

  const findByGithubStatus = (
    steps: Step[],
    status: GITHUB_WORKFLOW_RUN_STATUSES
  ): Step => {
    const filteredSteps = steps.filter((step) =>
      step.github_statuses.includes(status)
    );
    return filteredSteps.length > 0 ? filteredSteps[0] : steps[0];
  };

  const currentStep = findByGithubStatus(steps, status);

  const hasRunFailed = (index: number) => {
    if (index !== 2) {
      return false;
    }

    return run.data.conclusion != GITHUB_WORKFLOW_RUN_CONCLUSIONS.success;
  };

  return (
    <>
      {run && (
        <Box
          sx={{
            padding: 2,
          }}
        >
          <Typography sx={{ padding: 2 }}>
            Last run launched the {run.data.run_started_at} | last fetch at{" "}
            {new Date(lastRunFetch).toLocaleTimeString()}
          </Typography>
          <Stepper activeStep={currentStep.index}>
            {steps.map((step) => {
              const labelProps: {
                optional?: React.ReactNode;
                error?: boolean;
              } = {};
              if (hasRunFailed(step.index)) {
                labelProps.optional = (
                  <Typography variant="caption" color="error">
                    Failure
                  </Typography>
                );
                labelProps.error = true;
              }

              return (
                <Step key={step.label}>
                  <StepLabel {...labelProps}>{step.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
      )}
    </>
  );
};

export default MpsDemoScriptStepper;
