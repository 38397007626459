import { Box, Typography } from "@mui/material";
import { useReleases } from "Hooks/Github";
import get from "lodash/get";
import React from "react";

interface VersionStatusProps {
  repoName: string;
  prodVersion: string;
  devVersion: string;
}

const VersionStatus: React.FC<VersionStatusProps> = ({
  repoName,
  devVersion,
  prodVersion,
}) => {
  const { data } = useReleases({
    repo: repoName,
  });

  const releases = get(data, "data", []);

  const getStatusText = () => {
    if (devVersion === prodVersion) {
      return "Version already up to date on prod";
    } else {
      const prodRelease = releases.find(
        (release: { tag_name: string }) => release.tag_name === prodVersion
      );
      const prodPublishedAt = prodRelease ? prodRelease.published_at : null;

      if (prodPublishedAt) {
        // Count how many releases are published after the prodVersion's published date
        const aheadCount = releases.filter((release) => {
          if (!release.published_at) {
            return false;
          }
          return new Date(release.published_at) > new Date(prodPublishedAt);
        }).length;
        return aheadCount
          ? `Develop is ${aheadCount} version(s) ahead Prod`
          : "";
      }
    }
  };

  return (
    <Box>
      <Typography
        variant="caption"
        color="text.secondary"
        display="block"
        sx={{
          mt: 0.5,
          fontSize: "0.75rem",
        }}
      >
        {getStatusText()}
      </Typography>
    </Box>
  );
};

export default VersionStatus;
