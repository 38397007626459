import { Button, Grid, Typography } from "@mui/material";
import {
  useDeploymentStatuses,
  useFileContent,
  useTriggerWorkflow,
} from "Hooks/Github";
import get from "lodash/get";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { getServiceGroups } from "utils";

import DemoDeploymentList from "./DemoDeploymentList";
import GroupServiceCard from "./GroupServiceCard";

export const DONT_UPDATE = "Don't update";
const initialServices: Record<string, string> = {};

const DemoDeployment: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null);
  const { data: devManifestText } = useFileContent(
    "releases",
    "/manifests/dev.json"
  );
  const devManifest: Record<string, string> = JSON.parse(
    devManifestText ?? "{}"
  );

  const [servicesToDeploy, setServicesToDeploy] =
    useState<Record<string, string>>(initialServices);

  useEffect(() => {
    Object.keys(devManifest).forEach((service) => {
      initialServices[service] =
        service === "integration-testing" ? devManifest[service] : DONT_UPDATE;
    });
  }, [devManifest]);

  const [deployToDemoStatus] = useDeploymentStatuses([
    {
      repo: "releases",
      environment: "demo",
    },
  ]);

  const isDeploymentToDemoRunning = [
    "in_progress",
    "queued",
    "pending",
  ].includes(get(deployToDemoStatus, "data.data.[0].state"));

  const { mutateAsync: triggerWorkflow, isLoading: isTriggeringWorkflow } =
    useTriggerWorkflow();

  const startDeploymentToDemo = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    try {
      event.preventDefault();
      const versionsToUpdate = Object.fromEntries(
        Object.entries(servicesToDeploy).filter(
          ([, value]) => value !== DONT_UPDATE
        )
      );

      await triggerWorkflow({
        workflow_id: "deploy-to-demo.yml",
        repo: "releases",
        ref: "main",
        inputs: {
          IS_CUSTOM_RELEASE: "true",
          CUSTOM_VERSIONS: JSON.stringify(versionsToUpdate),
        },
      });

      enqueueSnackbar(
        "Started the automated tests, the deployment will begin in a few minutes.",
        { variant: "success" }
      );
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to trigger the deployment.", {
        variant: "error",
      });
    }
  };

  const copyManifestToClipboard = () => {
    const versionsToUpdate = Object.fromEntries(
      Object.entries(servicesToDeploy).filter(
        ([, value]) => value !== DONT_UPDATE
      )
    );
    navigator.clipboard.writeText(JSON.stringify(versionsToUpdate, null, 2));
    enqueueSnackbar("Copied the manifest to the clipboard.", {
      variant: "success",
    });
  };

  const handleVersionChange = (
    serviceName: string,
    newOption: { label: string; value: string }
  ) => {
    setServicesToDeploy((prev) => ({
      ...prev,
      [serviceName]: newOption.value,
    }));
  };

  const groupedServices = getServiceGroups(devManifest);

  return (
    <form onSubmit={startDeploymentToDemo} ref={formRef}>
      <Grid container spacing={2} sx={{ paddingLeft: 2, maxWidth: "800px" }}>
        {Object.entries(groupedServices).map(([group, services]) => {
          if (services.length === 0) return null;
          return (
            <Grid item xs={12} key={group}>
              <GroupServiceCard
                title={group}
                repoNames={services}
                onVersionChange={handleVersionChange}
                servicesToDeploy={servicesToDeploy}
              />
            </Grid>
          );
        })}

        <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
          <Typography variant="h5" sx={{ paddingLeft: 2, marginBottom: 3 }}>
            Selected Services for Deployment:
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{ paddingLeft: 2, marginBottom: 3, maxWidth: "800px" }}
          >
            <DemoDeploymentList servicesToDeploy={servicesToDeploy} />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "right" }}>
          <Button
            variant="outlined"
            onClick={copyManifestToClipboard}
            sx={{ mr: 2 }}
          >
            COPY MANIFEST
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={isDeploymentToDemoRunning || isTriggeringWorkflow}
          >
            DEPLOY
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default DemoDeployment;
