import { Autocomplete, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useReleases } from "Hooks/Github";
import get from "lodash/get";
import React, { PropsWithChildren } from "react";

import { DONT_UPDATE } from "./DemoDeployment";

interface VersionInputProps {
  repoName: string;
  latestVersion?: string;
  version: string;
  onVersionChange: (
    newOption: { label: string; value: string },
    latestVersion: string | undefined
  ) => void;
}

const VersionInput: React.FC<PropsWithChildren<VersionInputProps>> = ({
  repoName,
  latestVersion,
  onVersionChange,
  version,
}) => {
  const latestDevOption = {
    value: "dev",
    label: `Latest on dev (${latestVersion})`,
  };
  const prodOption = {
    value: "prod",
    label: DONT_UPDATE,
  };

  const { data } = useReleases({
    repo: repoName,
  });

  const releases = get(data, "data", []);

  const options = releases.map((release) => {
    return {
      value: release.tag_name,
      label: `${release.tag_name} (${dayjs(release.published_at).fromNow()})`,
    };
  });

  options.unshift(latestDevOption);
  options.unshift(prodOption);

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      value={options.find((option) => option.value === version)}
      defaultValue={
        options.find((option) => option.value === version) || options[0]
      }
      onChange={(_, newValue) => {
        if (newValue?.value) {
          onVersionChange(
            newValue as { label: string; value: string },
            latestVersion
          );
        }
      }}
      renderInput={(params) => (
        <TextField {...params} name={repoName} label="version" />
      )}
    />
  );
};

export default VersionInput;
