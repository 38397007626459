import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { RestEndpointMethodTypes } from "@octokit/plugin-rest-endpoint-methods";
import {
  usePollWorkflowRunUntilCompleted,
  useTriggerWorkflow,
  useWorkflowRuns,
} from "Hooks/Github";
import React, { SyntheticEvent, useState } from "react";
import { ArrowDown } from "react-feather";
import { ENV_FULL_NAME } from "Types/env";

import MpsDemoScriptStepper from "./Stepper";

const MpsDemoScript: React.FC = ({}) => {
  const repositoryName = "mps-demo-script";
  const workflowId = "build-and-run.yml";

  const [environment, setEnvironment] = useState<ENV_FULL_NAME>(
    ENV_FULL_NAME.DEV
  );

  const { mutateAsync: triggerWorkflow } = useTriggerWorkflow();
  const { data: workflowList, refetch: refetchWorkflowRuns } = useWorkflowRuns(
    repositoryName,
    workflowId
  );

  const workflowRunId = findCorrectWorkflow(workflowList, workflowId);

  const { data: run, dataUpdatedAt: lastRunFetch } =
    usePollWorkflowRunUntilCompleted(repositoryName, workflowRunId);

  const onRunLaunch = async (e: SyntheticEvent) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    await triggerWorkflow({
      workflow_id: workflowId,
      repo: repositoryName,
      ref: "main",
      inputs: {
        token: form.leanspacetoken.value,
        environment: environment,
        exotrailUsername: form.exotrailusername.value,
        exotrailPwd: form.exotrailpassword.value,
        satelliteTag: form.satellitetag.value,
        groundStationsTag: form.groundstationstag.value,
        orbitTag: form.orbittag.value,
        tleLine1: form.tleline1.value,
        tleLine2: form.tleline2.value,
      },
    });
  };

  const onWorkflowRefetch = () => {
    refetchWorkflowRuns();
  };

  return (
    <>
      <Box
        sx={{
          padding: 2,
        }}
      >
        <Typography sx={{ padding: 2 }} variant="h5">
          MPS Demo script
        </Typography>
        <Typography sx={{ padding: 2 }}>Connection settings</Typography>
        <form onSubmit={onRunLaunch}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              padding: 2,
            }}
          >
            <Tooltip title="Leanspace JWT token, found in the console (settings>API)">
              <TextField
                id="leanspacetoken"
                name="leanspacetoken"
                required
                label="Leanspace token"
                sx={{ paddingRight: 10 }}
              />
            </Tooltip>
            <Tooltip title="On which environment data is generated">
              <FormControl required>
                <FormLabel id="environment">Environment</FormLabel>
                <RadioGroup
                  row
                  defaultValue={ENV_FULL_NAME.DEV}
                  onChange={(event) =>
                    setEnvironment(event.target.value as ENV_FULL_NAME)
                  }
                >
                  <FormControlLabel
                    value={ENV_FULL_NAME.DEV}
                    control={<Radio />}
                    label={ENV_FULL_NAME.DEV}
                    labelPlacement="bottom"
                  />
                  <FormControlLabel
                    value={ENV_FULL_NAME.PROD}
                    control={<Radio />}
                    label={ENV_FULL_NAME.PROD}
                    labelPlacement="bottom"
                  />
                </RadioGroup>
              </FormControl>
            </Tooltip>
          </Box>
          <Accordion>
            <AccordionSummary expandIcon={<ArrowDown />}>
              Optional parameters
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ padding: 2 }}>
                Exotrail settings override
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  padding: 2,
                }}
              >
                <Tooltip title="If wanted, override the exotrail API username">
                  <TextField
                    id="exotrailusername"
                    name="exotrailusername"
                    label="Exotrail Username"
                    sx={{ paddingRight: 10 }}
                  />
                </Tooltip>
                <Tooltip title="If wanted, override the exotrail API password">
                  <TextField
                    id="exotrailpassword"
                    name="exotrailpassword"
                    label="Exotrail password"
                  />
                </Tooltip>
              </Box>
              <Typography sx={{ padding: 2 }}>Tag settings</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  padding: 2,
                }}
              >
                <Tooltip title="Tag to use to find the <b>unique</b> satellite to apply the script to">
                  <TextField
                    id="satellitetag"
                    name="satellitetag"
                    defaultValue={"mpslite"}
                    label="Satellite tag"
                    sx={{ paddingRight: 10 }}
                  />
                </Tooltip>
                <Tooltip title="Tag to use to find the ground stations eligible for contacts">
                  <TextField
                    id="groundstationstag"
                    name="groundstationstag"
                    defaultValue={"mpslite"}
                    label="Ground stations tag"
                  />
                </Tooltip>
              </Box>
              <Typography sx={{ padding: 2 }}>Orbits settings</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  padding: 2,
                }}
              >
                <Tooltip title="Tag to use to find the orbit of the satellite used for tle propagation and orbital events generation">
                  <TextField
                    id="orbittag"
                    name="orbittag"
                    defaultValue={"mpslite"}
                    label="Orbit tag"
                    sx={{ paddingRight: 10 }}
                  />
                </Tooltip>
                <Tooltip title="First line of the TLE to apply to the orbit">
                  <TextField
                    id="tleline1"
                    name="tleline1"
                    label="TLE, Line 1"
                    sx={{ paddingRight: 10 }}
                  />
                </Tooltip>
                <Tooltip title="Second line of the TLE to apply to the orbit">
                  <TextField
                    id="tleline2"
                    name="tleline2"
                    label="TLE, Line 2"
                  />
                </Tooltip>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Box sx={{ padding: 2, textAlign: "right" }}>
            <Button
              variant="contained"
              sx={{ marginRight: 10 }}
              onClick={onWorkflowRefetch}
            >
              Re-fetch last workflow run
            </Button>
            <Button variant="contained" type="submit">
              Execute script
            </Button>
          </Box>
        </form>
      </Box>

      {run && <MpsDemoScriptStepper run={run} lastRunFetch={lastRunFetch} />}
    </>
  );
};

const findCorrectWorkflow = (
  workflowList:
    | RestEndpointMethodTypes["actions"]["listWorkflowRunsForRepo"]["response"]
    | undefined,
  workflowId: string
) => {
  if (!workflowList) {
    return undefined;
  }

  const workflowToListenOnList = workflowList.data.workflow_runs.filter((wf) =>
    wf.path.includes(workflowId)
  );

  if (workflowToListenOnList.length > 0) {
    // take the most recent one if multiple
    return workflowToListenOnList.sort(
      (a, b) =>
        Date.parse(b.run_started_at as string) -
        Date.parse(a.run_started_at as string)
    )[0].id;
  }
  return undefined;
};

export default MpsDemoScript;
