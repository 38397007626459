import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFileContent } from "Hooks/Github";
import React from "react";

import VersionInput from "./VersionInput";
import VersionStatus from "./VersionStatus";

interface GroupServiceCardProps {
  title: string;
  repoNames: string[];
  servicesToDeploy: Record<string, string>;
  onVersionChange: (
    serviceName: string,
    newOption: { label: string; value: string }
  ) => void;
}

const GroupServiceCard: React.FC<GroupServiceCardProps> = ({
  title,
  repoNames,
  onVersionChange,
  servicesToDeploy,
}) => {
  const { data: devManifestText } = useFileContent(
    "releases",
    "/manifests/dev.json"
  );
  const devManifest: Record<string, string> = JSON.parse(
    devManifestText ?? "{}"
  );

  const { data: prodManifestText } = useFileContent(
    "releases",
    "/manifests/prod.json"
  );
  const prodManifest: Record<string, string> = JSON.parse(
    prodManifestText ?? "{}"
  );

  return (
    <Box>
      <Card sx={{ height: "100%", bgcolor: "background.paper" }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {title === "Ungrouped" ? "" : title}
          </Typography>
          <Grid container spacing={2}>
            {repoNames.map((repoName) => (
              <Grid item xs={12} key={repoName}>
                <Grid container spacing={2} alignItems="flex-start">
                  <Grid item xs={5}>
                    {repoName}
                  </Grid>
                  <Grid item xs={7}>
                    {repoName === "integration-testing" ? (
                      <Autocomplete
                        freeSolo
                        options={[devManifest[repoName]]}
                        defaultValue={devManifest[repoName]}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={repoName}
                            label="version"
                          />
                        )}
                      />
                    ) : (
                      <>
                        <VersionInput
                          repoName={repoName}
                          latestVersion={devManifest[repoName]}
                          version={servicesToDeploy[repoName]}
                          onVersionChange={(newOption) =>
                            onVersionChange(repoName, newOption)
                          }
                        />
                        <VersionStatus
                          repoName={repoName}
                          devVersion={devManifest[repoName] || ""}
                          prodVersion={prodManifest[repoName] || ""}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default GroupServiceCard;
