import { Grid } from "@mui/material";
import { useFileContent } from "Hooks/Github";
import React, { PropsWithChildren } from "react";

import { DONT_UPDATE } from "./DemoDeployment";

interface DemoDeploymentListProps {
  servicesToDeploy: Record<string, string>;
}

const DemoDeploymentList: React.FC<
  PropsWithChildren<DemoDeploymentListProps>
> = ({ servicesToDeploy }) => {
  const { data: devManifestText } = useFileContent(
    "releases",
    "/manifests/dev.json"
  );
  const devManifest: Record<string, string> = JSON.parse(
    devManifestText ?? "{}"
  );

  const { data: demoManifestText } = useFileContent(
    "releases",
    "/manifests/demo.json"
  );
  const demoManifest: Record<string, string> = JSON.parse(
    demoManifestText ?? "{}"
  );

  return (
    <>
      {Object.entries(servicesToDeploy).map(([service, version]) => {
        if (version === DONT_UPDATE || version === "prod") return null;
        if (version.includes(devManifest[service]) || version === "dev")
          return (
            <>
              <Grid item xs={3} key={service}>
                {service}
              </Grid>
              <Grid item xs={3}>
                {demoManifest[service]}
              </Grid>
              <Grid item xs={1}>
                👉
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "right" }}>
                {service === "integration-testing"
                  ? version
                  : `Latest on dev ${devManifest[service]}`}
              </Grid>
            </>
          );
        return (
          <>
            <Grid item xs={3} key={service}>
              {service}
            </Grid>
            <Grid item xs={3}>
              {demoManifest[service]}
            </Grid>
            <Grid item xs={1}>
              👉
            </Grid>
            <Grid item xs={3} sx={{ textAlign: "right" }}>
              {version}
            </Grid>
          </>
        );
      })}
    </>
  );
};
export default DemoDeploymentList;
