const DEFAULT_AWS_REGION = "eu-central-1";
const PARIS_AWS_REGION = "eu-west-3";

export const COGNITO_POOLS = {
  PROD: {
    clientAppId: "6041nug322chogs2li9b3nuq1f",
    userPoolWebClientId: "6041nug322chogs2li9b3nuq1f",
    accountId: "leanspace",
    userPoolId: "eu-central-1_0U6kJK2K0",
    region: DEFAULT_AWS_REGION,
  },
  DEMO: {
    clientAppId: "337beoumrcql1n1bihvfed5a1i",
    userPoolWebClientId: "337beoumrcql1n1bihvfed5a1i",
    accountId: "leanspace",
    userPoolId: "eu-central-1_KtYM219ce",
    region: DEFAULT_AWS_REGION,
  },
  DEV: {
    clientAppId: "2aof00bhnk8bl5m919brurfj5q",
    userPoolWebClientId: "2aof00bhnk8bl5m919brurfj5q",
    accountId: "leanspace",
    userPoolId: "eu-central-1_gMS9jwzCN",
    region: DEFAULT_AWS_REGION,
    displayName: "DEVELOP",
  },
  PROM001: {
    clientAppId: "3tqkcm28jt3123f2gh6q4ef2qh",
    userPoolWebClientId: "3tqkcm28jt3123f2gh6q4ef2qh",
    accountId: "leanspace",
    userPoolId: "eu-west-3_nudSLIJpy",
    region: PARIS_AWS_REGION,
  },
  AITMULT: {
    clientAppId: "1pktddf784fi1p101jerr2bj8r",
    userPoolWebClientId: "1pktddf784fi1p101jerr2bj8r",
    accountId: "leanspace",
    userPoolId: "eu-central-1_X5KIYJLmo",
    region: DEFAULT_AWS_REGION,
  },
  UMBRELA: {
    clientAppId: "52h9d17uim857s3gtaea9f348c",
    userPoolWebClientId: "52h9d17uim857s3gtaea9f348c",
    accountId: "leanspace",
    userPoolId: "eu-central-1_yF0sO5VWY",
    region: DEFAULT_AWS_REGION,
  },
};
